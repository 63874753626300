* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    visibility: visible;
    opacity: 1;
    min-height: 100%;
    height: 100%;
    font-family:-apple-system,
       BlinkMacSystemFont,
       'Segoe UI',
       Roboto,
       Oxygen,
       Ubuntu,
       Cantarell,
       'Open Sans',
       'Helvetica Neue',
       sans-serif;

    background-color: rgb(24,24,24);
}

.portfolio-section {
    width: 100%;
    height: 100vh;
    position: absolute;
    // background-image: linear-gradient(60deg, #3d3393 0%, #2b76b9 37%, #2cacd1 65%, #35eb93 100%);
    // background-image: linear-gradient(-225deg, #FF3CAC 0%, #562B7C 52%, #2B86C5 100%);
    // background-image: linear-gradient(-225deg, #FF057C 0%, #8D0B93 50%, #321575 100%);
    background-image: linear-gradient(-225deg,rgb(24,24,24) 0%, 90%, rgba(224, 34, 56, 0.8) 100%);

    top: 0;
    left: 0;

    .portfolio {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        .portfolio-title {
            color: white;
            padding: 30px;
            @media only screen and (min-width: 600px) {
                font-size: 4rem;
            }

            @media only screen and (max-width: 600px) {
                font-size: 2rem;
            }
            text-align: center;
        }

        .portfolio-list {
            height: 90vh;
            width: 100vw;
            padding: 2vh 15vw;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-content: flex-start;
            z-index: 10;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            //  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 95%, transparent 100%);
            //     mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 95%, transparent 100%);

            &::-webkit-scrollbar {
                display: none;
            }

            .card-container {
                transition: width 0.5s height 0.5s;
                z-index: 50;
                
                @media only screen and (min-width: 600px) {
                    margin: 1rem 1rem;
                    width: 15rem;
                    height: 20rem;
                }
        
                @media only screen and (max-width: 600px) {
                    margin: 0.5rem 0.5rem;
                    width: 100%;
                    height: 5rem;
                }

                &.clicked {
                    cursor: default;

                    @media only screen and (min-width: 600px) {
                        width: 50vw;
                    }
            
                    @media only screen and (max-width: 600px) {
                        width: 100%;
                        height: 60%;
                        padding: 15px;
                        z-index: 100;
                        position: absolute;
                    }

                    .card {
                        @media only screen and (max-width: 600px) {
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            border-radius: 2rem;
                            padding: 10px;
                            > * {
                                padding: 10px;
                            }
                        }

                        box-shadow: 5px 5px 15px rgba(127, 127, 127, 0.3),
                            0px 0px 20px rgba(255, 255, 255, 0.6);
                        overflow-y: scroll;
                        -ms-overflow-style: none;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .fa-chevron-down {
                            display: none;
                        }

                        .description .short {
                            display: none;
                        }

                        .expanded {
                            width: 100%;
                            display: flex;
                            color: white;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 0 1rem;
                            margin-top: 20px;

                            .stack li {
                                position: relative;
                                padding-bottom: 2px;
                            }

                            .stack {
                                list-style: none;
                                text-align: center;
                            }

                            .stack li:before{
                                content: '';
                                position: absolute;
                                border-right:2px solid white;
                                border-bottom:2px solid white;
                                width:5px;
                                height:5px;
                                top: calc(50% - 2px);
                                left: -10px;
                                transform: translateY(-50%) rotate(-45deg);
                            }

                            .app-link {
                                display: flex;
                                align-items: center;
                                text-align: center;
                                justify-content: center;
                                color: white;
                                width: 30%;
                                height: 40px;
                                text-decoration: none;
                                border: 1px solid rgba(256, 256, 256, 0.6);
                                border-radius: 6px;
                                font-weight: 600;
                                box-shadow: 
                                    0px 0px 10px rgba(255, 255, 255, 0.3),
                                    inset 0px 0px 10px rgba(255,255,255,0.3);
                                position: relative;
                                font-size: 1rem;

                            }

                            .git-link {
                                display: flex;
                                text-decoration: none;
                                align-items: center;
                                justify-content: center;
                                font-size: 30px;
                                width: 40px;
                                height: 40px;
                                color: rgba(256, 256, 256, 0.6);
                                border-radius: 6px;
                                border: 1px solid rgba(256, 256, 256, 0.6);
                                box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3),
                                inset 0px 0px 10px rgba(255, 255, 255, 0.3);
                             }
                        }

                       
                    }
                }

                &:not(.clicked) {
                    cursor: pointer;

                    .card {
                        @media only screen and (max-width: 600px) {
                            border-radius: 1rem;
                            padding: 1rem;
                        }

                        .expanded {
                            display: none;
                        }

                        .title {
                            @media only screen and (max-width: 600px) {
                                font-size: 1rem;
                            }
                        }

                        .description .long {
                            display: none;
                        }

                        .description .short  {
                            @media only screen and (max-width: 600px) {
                                display: none;
                            }
                        }

                        .fa-chevron-down {
                            @media only screen and (max-width: 600px) {
                                transform: rotate(-90deg);
                            }
                        }

                        img {
                            @media only screen and (max-width: 600px) {
                                height: 2.5rem;
                                width: 2.5rem;
                            }
                        }
                    }
                }

                .card {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
                    -webkit-backdrop-filter: blur(2rem);
                    backdrop-filter: blur(2rem);
                    box-shadow: 5px 5px 15px rgba(127, 127, 127, 0.3);
                    // -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
                    // mask-image: linear-gradient(to bottom, black 90%, transparent 100%);

                    

                    @media only screen and (min-width: 600px) {
                        border-radius: 2rem;
                        padding: 2rem;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                    }

                    &:hover {
                        box-shadow: 5px 5px 15px rgba(127, 127, 127, 0.3),
                            0px 0px 20px rgba(255,255,255,0.6);
                    }

                    .title {
                        color: white;
                        text-align: center;
                    }

                    img {
                        object-fit: scale-down;
                        border-radius: 50%;
                        padding: 5px;
                        height: 4rem;
                        width: 4rem;
                    }

                    p {
                        color: white;
                        text-align: center;
                    }

                    i {
                        color: white;
                    }
                }
            }
        }

        .portfolio-placeholder {
            height: 40%;
            width: 40%;
            margin-top: 100px;
            margin-left: auto;
            margin-right: auto;
            background-image: url(../img/undraw_to_the_moon_v1mv.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 3;
        }

        .portfolio-coming-soon {
            color: white;
            width: 100%;
            text-align: center;
        }
    }

    .circle1, .circle2 {
        width: 20vh;
        height: 20vh;
        position: absolute;
        background: linear-gradient(to right bottom, rgba(255,255,255,0.7), rgba(255,255,255,0.3));
        border-radius: 50%;
        z-index: 2;
    }

    .circle1 {
        top: 10%;
        right: 15%;
    }

    .circle2 {
        bottom: 10%;
        left: 10%;
    }
}
